import React, { Fragment, useMemo } from "react";

import { CriticalError } from "@settings/CriticalError";
import { useAuth } from "@api/auth";

import { useDealerManagementContext } from "./context";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { Pager } from "~/components/Pager";
import { Table } from "~/components/Table";

import { Redirect } from "~/components/Redirect";
import { PrimaryButton } from "~/components/Button";

import { userColumns } from "./columns";
import { Search } from "./Search";
import AddDealerMobile from "./components/AddDealerMobile";

const useStyles = makeStyles((theme) => ({
	contentContainer: {
		display: "flex",
		flexFlow: "row nowrap",
		height: "100%",
		overflow: "hidden",
	},
	tableContainer: {
		display: "flex",
		flexFlow: "column nowrap",
		width: "100%",
		height: "100%",
		overflow: "auto",
	},
	table: {
		minHeight: 350,
		height: "100%",
		userSelect: "none",
	},
	pager: {
		marginLeft: "auto",
		padding: 10,
	},
	companyPanel: {
		borderTop: `1px solid ${theme.palette.borderInternal.main}`,
	},
	addDealerButton: {
		alignSelf: "center",
		margin: 10,
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			display: "none",
		},
	},
	topBar: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
}));

export const DealerList: React.FC = () => {
	const { user } = useAuth();
	const { rows, page, loading, error, changePage, showCreateCompanyModal } =
		useDealerManagementContext();
	const styles = useStyles();

	const paging = useMemo(() => {
		return page?.count && page.count > 1 ? (
			<Box className={styles.pager}>
				<Pager
					numeric
					current={page.current}
					count={page.count}
					onChange={changePage}
				/>
			</Box>
		) : null;
	}, [page, changePage, styles.pager]);

	const content = useMemo(() => {
		const doCreateCompany = () => {
			showCreateCompanyModal({
				action: "Add Dealer",
				label: "Add Dealer",
			});
		};
		if (!user?.autocorpAdmin) {
			return <Redirect to="/admin/login-history/" />;
		}
		if (error) {
			return (
				<CriticalError
					message={"Unable to retrieve list of dealers!"}
					error={error}
				/>
			);
		}

		return (
			<Fragment>
				<Box className={styles.topBar}>
					<Search />
					<PrimaryButton
						onClick={doCreateCompany}
						className={styles.addDealerButton}
					>
						Add Dealer
					</PrimaryButton>
					<AddDealerMobile onClick={doCreateCompany} />
				</Box>
				<Box className={styles.contentContainer}>
					<Box className={styles.tableContainer}>
						<Box className={styles.table}>
							<Table
								compact
								columns={userColumns}
								rows={rows || []}
								loading={loading}
							/>
						</Box>
						{paging}
					</Box>
				</Box>
			</Fragment>
		);
	}, [
		error,
		loading,
		paging,
		rows,
		showCreateCompanyModal,
		styles,
		user?.autocorpAdmin,
	]);

	return (
		<Box
			display="flex"
			flexDirection="column"
			flexWrap="nowrap"
			height="100%"
		>
			{content}
		</Box>
	);
};
