import React from "react";

import { LayoutComponent } from "~/model/layout";
import TabbedNav from "~/components/TabbebNav";
import { useAuth } from "@api/auth";

export const AdminLayout: LayoutComponent<never> = () => {
	const { user } = useAuth();

	return (
		<TabbedNav
			tabs={[
				{
					title: "Login History",
					path: "/admin/login-history",
				},
				user?.autocorpAdmin && {
					title: "Dealer Management",
					path: "/admin/dealer-management",
				},

				user?.autocorpAdmin && {
						title: "CTA - Global",
						path: "/admin/cta-management/global",
					},
				user?.autocorpAdmin && {
						title: "CTA - Dealer",
						path: "/admin/cta-management/dealer",
					},
			]}
		/>
	);
};
