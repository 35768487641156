import { createPrivateView, ViewComponent } from "~/model/view/private";
import { AdminLayout } from "@admin/AdminLayout";

import { getUser } from "@api/auth";

export const createAdminView: typeof createPrivateView = (
	component,
	props = {},
) => {
	const getTitle = () => {
		try {
			const user = getUser();
			return user?.selectedCompany?.name ?? "";
		} catch {
			return "";
		}
	};

	return createPrivateView(component, {
		...props,
		showBack: true,
		backTo: "/leads/",
		title: getTitle,
		layoutProps: {
			topNav: {
				sub: AdminLayout,
			},
		},
	});
};

export type { ViewComponent };
