import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { Spinner } from "~/components/Spinner";

const useStyles = makeStyles(() => ({
	loadingWrapper: {
		display: "flex",
		width: "100%",
		minWidth: 375,
		height: "100%",
	},
	loading: {
		height: 45,
		alignSelf: "center",
	},
}));

export const Loading: React.FC = () => {
	const styles = useStyles();
	return (
		<Box className={styles.loadingWrapper}>
			<Spinner className={styles.loading} />
		</Box>
	);
};
