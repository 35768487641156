import React, { useState, useCallback } from "react";

import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";

import { Input } from "~/components/Input";
import { useDealerManagementContext } from "./context";

const useStyles = makeStyles(({ palette, dashboard }) => {
	const inputPadding = "15px";

	return {
		container: {
			display: "flex",
			flexFlow: "row nowrap",
			justifyContent: "space-between",
			margin: `${dashboard?.innerContent?.paddingTop || 0}px 20px 20px`,
		},
		inputControl: {
			padding: inputPadding,
			paddingRight: 0,
			width: "100%",
		},
		inputBoxRoot: {
			backgroundColor: "#FDFDFD",
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: "#CACACA",
			},
		},
		inputBoxLabel: {
			color: `${palette.text.primary} !important`,
		},
	};
});

export const Search: React.FC = () => {
	const { setSearch, search: existingSearch } = useDealerManagementContext();
	const [value, setValue] = useState(existingSearch);
	const styles = useStyles();

	const doSearch = useCallback(
		(search: string) => {
			setValue(search);
			setSearch(search);
		},
		[setSearch],
	);

	return (
		<Box className={styles.container}>
			<Box>
				<Input
					id="search-text"
					inputLabel="Search"
					placeholder="Search"
					variant="outlined"
					fullWidth={false}
					value={value}
					InputLabelProps={{
						className: styles.inputBoxLabel,
						classes: {
							focused: styles.inputBoxLabel,
						},
					}}
					InputProps={{
						color: "secondary",
						classes: {
							input: styles.inputControl,
							root: styles.inputBoxRoot,
						},
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment
								position="end"
								style={{
									visibility: value ? "visible" : "hidden",
								}}
							>
								<IconButton
									size="small"
									onClick={() => {
										doSearch("");
									}}
								>
									<ClearIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
					onChange={(e) => {
						doSearch(e.currentTarget.value);
					}}
				/>
			</Box>
		</Box>
	);
};
