import React, { useState } from "react";

import { Typography } from "~/components/Typography";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Box } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";

import { useStyles } from "./styles";

interface IAddDealerMobile {
	onClick: () => void;
}

const AddDealerMobile = ({ onClick }: IAddDealerMobile) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(e.currentTarget);
	};

	const handleClose =
		(cb?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void) =>
		(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			e.preventDefault();
			if (cb) cb(e);
			setAnchorEl(null);
		};

	const styles = useStyles();

	return (
		<Box className={styles.root}>
			<IconButton
				className={styles.optionsButton}
				onClick={handleMenuClick}
			>
				<MoreHorizIcon />
			</IconButton>
			<Menu
				keepMounted
				classes={{
					paper: styles.menu,
				}}
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={handleClose()}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<MenuItem>
					<Typography onClick={onClick} variant="body1">
						Add Dealer
					</Typography>
				</MenuItem>
			</Menu>
		</Box>
	);
};

export default AddDealerMobile;
