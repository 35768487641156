import gql from "graphql-tag";
import { FieldConfig } from "./fields";

export const GetLeadCountsByProduct = gql`
	query GetLeadCountsByProduct($company: IntFilter) {
		leadJourneys(
			filter: {
				lead: { companyId: $company, read: { equalTo: false } }
				productId: { isNull: false }
			}
		) {
			groupedAggregates(groupBy: [PRODUCT_ID]) {
				distinctCount {
					leadId
				}
				keys
			}
		}

		products {
			nodes {
				id
				displayName
				name
			}
		}
	}
`;

export const GetProducts = gql`
	query GetProducts($companyId: Int) {
		products(orderBy: ID_ASC, condition: { enabled: true }) {
			nodes {
				id
				name
				displayName
				fieldConfigs {
					nodes {
						...FieldConfig

						fieldsByConfig(condition: {companyId: $companyId}) {
							nodes {
								id
								topic
								value
							}
						}
					}
				}

				companyProducts(condition: {companyId: $companyId}) {
					nodes {
						id
						enabled
					}
				}
				
				productIntegrations(
					condition: {enabled: true}
					filter: {integration: {enabled: {equalTo: true}}}
				) {
					nodes {
						id
						enabled
						integration {
							id
							name
							displayName
							enabled
							fieldConfigs {
								nodes {
									...FieldConfig
								}
							}
							companyIntegrations(condition: {companyId: $companyId}) {
								nodes {
									id
									enabled
									companyProductIntegrations {
										nodes {
											id
											enabled
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	${FieldConfig}
`;

export const CreateCompanyProduct = gql`
	mutation CreateCompanyProduct($input: CreateCompanyProductInput!) {
		createCompanyProduct(input: $input) {
			company {
				name
				id
				companyProducts {
					nodes {
						id
						enabled
						product {
							id
							name
							displayName
							enabled
						}
					}
				}
			}
		}
	}
`;

export const UpdateCompanyProduct = gql`
	mutation UpdateCompanyProduct($input: UpdateCompanyProductInput!) {
		updateCompanyProduct(input: $input) {
			companyProduct {
				id
				enabled
				product {
					id
					name
					displayName
					enabled
				}
			}
		}
	}
`;
