import React from "react";

import { createAdminView, ViewComponent } from "@admin/view";

import { DealerManagementProvider } from "./_lib/context";

import { DealerList } from "./_lib/DealerList";

import { AppDrawer } from "~/components/Modal";
import { useViewContext, ViewContextProvider } from "./_lib/context";
import { FeedbackProvider } from "~/components/Feedback";

const DealerManagementModal = () => {
	const {
		state: {
			modal: { content: modalContent, props: modalProps },
		},
		actions: { closeModal },
	} = useViewContext();

	return (
		<>
			{modalContent && (
				<AppDrawer {...modalProps} onClose={closeModal}>
					{modalContent}
				</AppDrawer>
			)}
		</>
	);
};

const DealerManagement: ViewComponent = ({ navigate, location }) => {
	return (
		<ViewContextProvider>
			<FeedbackProvider>
				<DealerManagementProvider
					navigate={navigate}
					location={location}
				>
					<DealerList />
					<DealerManagementModal />
				</DealerManagementProvider>
			</FeedbackProvider>
		</ViewContextProvider>
	);
};

export default createAdminView(DealerManagement, {
	title: "dealer-management",
	internalPad: true,
	internalScroll: true,
});
