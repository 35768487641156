import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles(() => ({
	root: {
		backgroundColor: "white",
	},
	title: {
		textAlign: "center",
	},
}));

export interface IPublicAppDialogProps extends DialogProps {
	title?: string;
}

export const AppDialog: React.FC<IPublicAppDialogProps> = ({
	title = "",
	children,
	...props
}) => {
	const styles = useStyles();

	return (
		<Dialog {...props} aria-labelledby={`dialog-modal-${title}`}>
			{title && (
				<DialogTitle
					className={styles.title}
					id={`dialog-modal-${title}`}
					classes={{ root: styles.root }}
				>
					{title}
				</DialogTitle>
			)}
			<DialogContent dividers classes={{ root: styles.root }}>
				{children}
			</DialogContent>
		</Dialog>
	);
};
